<template>
  <section id="notfound">
    <div class="notfound">
      <div class="notfound-404">
        <div></div>
        <h1>404</h1>
      </div>
      <h2>Page not found</h2>
      <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
      <a href="#" @click="redirect">home page</a>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    redirect () {
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss">
  #nav {
    display: none;
  }
  #notfound {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    height: 100vh;
    .notfound {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 460px;
      width: 100%;
      text-align: center;
      line-height: 1.4;
      .notfound-404 {
        position: relative;
        width: 180px;
        height: 180px;
        margin: 0 auto 50px;
        &>div:first-child {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: #ffa200;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          border: 5px dashed #000;
          border-radius: 5px;
          &:before {
            content: '';
            position: absolute;
            left: -5px;
            right: -5px;
            bottom: -5px;
            top: -5px;
            -webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, .1) inset;
            box-shadow: 0 0 0 5px rgba(0, 0, 0, .1) inset;
            border-radius: 5px;
          }
        }
        h1 {
          font-family: cabin, sans-serif;
          color: #000;
          font-weight: 700;
          margin: 0;
          font-size: 90px;
          position: absolute;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          left: 50%;
          text-align: center;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    h2 {
      font-family: cabin, sans-serif;
      font-size: 33px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 7px;
    }
    p {
      font-family: cabin, sans-serif;
      font-size: 16px;
      color: #000;
      font-weight: 400;
    }
    a {
      font-family: cabin, sans-serif;
      display: inline-block;
      padding: 10px 25px;
      background-color: #8f8f8f;
      border: none;
      border-radius: 40px;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      -webkit-transition: .2s all;
      transition: .2s all;
      &:hover {
        background-color: #2c2c2c;
      }
    }
  }
</style>
